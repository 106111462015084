.tripical-map-container {
  width: 100%;
  height: 100%;
  position: relative;
}

#trip-map {
  font-family: SVN-Gilroy;
  font-size: 16px;
  color: #161c24;
  width: 100%;
  height: calc(70vh - 40px);
  border: 1.66667px solid #ffffff;
  filter: drop-shadow(0px 0.833333px 5px rgba(0, 0, 0, 0.15));
  border-radius: 16px;
}

#trip-map .maplibregl-popup-anchor-top .maplibregl-popup-tip {
  border-bottom-color: #161c24;
}

#trip-map .maplibregl-popup-anchor-bottom .maplibregl-popup-tip {
  border-top-color: #161c24;
}

#trip-map .maplibregl-popup-anchor-left .maplibregl-popup-tip {
  border-right-color: #161c24;
}

#trip-map .maplibregl-popup-anchor-right .maplibregl-popup-tip {
  border-left-color: #161c24;
}

#trip-map .custom-maplibregl-popup .maplibregl-popup-tip {
  display: none;
}

#trip-map .custom-maplibregl-popup .maplibregl-popup-content {
  padding: 3.82383px;
  border-radius: 36.7087px;
  box-shadow: none;
  box-shadow: 0px 1.91191px 11.4715px rgba(0, 0, 0, 0.15);
}

#trip-map .custom-maplibregl-popup.isFirstCheckpoint .maplibregl-popup-tip {
  display: unset;
}

#trip-map .custom-maplibregl-popup.isFirstCheckpoint .maplibregl-popup-content {
  background: #161c24;
  border-radius: 10px;
}

#trip-map
  .custom-maplibregl-popup.maplibregl-popup-anchor-bottom
  .maplibregl-popup-content.final-destination {
  position: absolute;
  bottom: -95px;
  left: -55px;
}

#trip-map
  .custom-maplibregl-popup.maplibregl-popup-anchor-top
  .maplibregl-popup-content.final-destination {
  position: absolute;
  bottom: -55px;
  left: -55px;
}

#trip-map .maplibregl-ctrl {
  border-radius: 50px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

#trip-map .maplibregl-ctrl.maplibregl-ctrl-attrib {
  display: block;
}

#trip-map .maplibregl-ctrl.cube3d-ctrl {
  position: absolute;
  bottom: 0;
  right: 106px;
}

#trip-map .maplibregl-ctrl.cube3d-ctrl button {
  padding: 10.18px 11.12px;
}

#trip-map .maplibregl-ctrl.vehicle-ctrl {
  position: absolute;
  bottom: 0;
  right: 160px;
}

#trip-map .maplibregl-ctrl.vehicle-ctrl button {
  padding: 7px 6px;
}

#trip-map .maplibregl-ctrl.location-ctrl {
  position: absolute;
  bottom: 0;
  right: 53px;
}

#trip-map .maplibregl-ctrl.location-ctrl button {
  padding: 7.58px 8.88px;
}

#trip-map .maplibregl-ctrl.view-detail-ctrl button {
  display: flex;
  width: 192px;
  height: 50px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
}

#trip-map .maplibregl-ctrl button {
  border-radius: 16px;
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#trip-map .maplibregl-ctrl button.maplibregl-ctrl-geolocate {
  padding: 6.75px;
}

#trip-map .maplibregl-ctrl button.maplibregl-ctrl-zoom-in {
  height: 50px;
  border-bottom: 1px solid #ededed;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#trip-map
  .maplibregl-ctrl
  button.maplibregl-ctrl-zoom-in
  .maplibregl-ctrl-icon {
  /* background-image: url("/static/images/map/plus.svg"); */
  background-size: cover;
  width: 22px;
  height: 22px;
}

#trip-map .maplibregl-ctrl button.maplibregl-ctrl-zoom-out {
  height: 50px;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#trip-map
  .maplibregl-ctrl
  button.maplibregl-ctrl-zoom-out
  .maplibregl-ctrl-icon {
  /* background-image: url("/static/images/map/minus.svg"); */
  background-size: cover;
  width: 12px;
  height: 2px;
}
.ant-input-group-addon {
  display: none;
}
@media (max-width: 768px) {
  #trip-map .maplibregl-ctrl button {
    width: 28px;
    height: 28px;
  }

  #trip-map .maplibregl-ctrl.cube3d-ctrl {
    right: 86px;
  }

  #trip-map .maplibregl-ctrl.cube3d-ctrl button {
    padding: 7.12px;
  }

  #trip-map .maplibregl-ctrl.vehicle-ctrl {
    right: 130px;
  }

  #trip-map .maplibregl-ctrl.vehicle-ctrl button {
    padding: 4px 7px;
  }

  #trip-map .maplibregl-ctrl.location-ctrl {
    right: 43px;
  }

  #trip-map .maplibregl-ctrl.location-ctrl button {
    padding: 7.33px 8px;
  }

  #trip-map .maplibregl-ctrl.maplibregl-ctrl-zoom-in,
  #trip-map .maplibregl-ctrl.maplibregl-ctrl-zoom-out {
    display: none;
  }

  #trip-map .maplibregl-ctrl.maplibregl-ctrl-attrib {
    display: block;
    font-size: 11px;
  }

  #trip-map .maplibregl-ctrl.view-detail-ctrl button {
    height: 28px;
    width: 97px;
  }
}
