@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroyBlackItalic.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyBlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroyBlack.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroyBold.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroyHeavyItalic.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyHeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroyBoldItalic.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroyHeavy.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyHeavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroyItalic.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroyLightItalic.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroyMediumItalic.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyMediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroyLight.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroyMedium.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-Gilroy.woff2') format('woff2'),
      url('assets/fonts/SVN-Gilroy.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroySemiBold.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroySemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroySemiBoldItalic.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroySemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroyThinItalic.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy';
    src: url('assets/fonts/SVN-GilroyThin.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy XLight';
    src: url('assets/fonts/SVN-GilroyXLight.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyXLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy XBold';
    src: url('assets/fonts/SVN-GilroyXBold.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyXBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy XBold';
    src: url('assets/fonts/SVN-GilroyXBoldItalic.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyXBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SVN-Gilroy XLight';
    src: url('assets/fonts/SVN-GilroyXLightItalic.woff2') format('woff2'),
      url('assets/fonts/SVN-GilroyXLightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }
  * {
    margin: 0;
  }
  root,
  body,
  html {
    font-family: 'SVN-Gilroy';
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    width: 100% !important;
  }
  :root[data-theme='dark'] .ant-select-selector {
    @apply !shadow-none;
  }
  :root[data-theme='dark'] .ant-picker {
    @apply !shadow-none;
  }

  .center {
    @apply flex items-center justify-center;
  }
}

.site-collapse-custom-collapse {
  @apply lg:h-[70px] mb-4 lg:mb-0  lg:min-w-[450px] xl:lg:min-w-[750px];
}
.site-collapse-custom-collapse.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 0px 16px;
}
.site-collapse-custom-collapse.ant-collapse-ghost
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-top: 0px;
  padding-bottom: 0px;
}
/* Prevent input zoom on ios device */
@media screen and (max-width: 640px) {
  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select:focus,
  textarea {
    font-size: 16px;
  }
}
.ant-modal-confirm .ant-modal-confirm-paragraph {
  max-width: none;
}
.ant-badge .ant-badge-count {
  line-height: 24px;
}
.customAvatarAccount.ant-upload-wrapper .ant-upload {
  border: none;
  background-color: transparent;
  border-radius: 100%;
  padding: 0;
  overflow: hidden;
}

.ant-cascader-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40% !important;
}
.ant-cascader-menus {
  min-width: 44rem !important;
}
.ant-cascader-menu:nth-child(2) {
  max-width: 60% !important;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: transparent !important;
  color: #ff6c00 !important;
}
.ant-cascader-menu-item {
  border-bottom: 1px solid #e6e9ee;
  width: 95%;
}

.shadow-card {
  box-shadow: 0px 0.249px 0.747px 0px rgba(0, 0, 0, 0.02),
    0px 0.599px 1.796px 0px rgba(0, 0, 0, 0.03),
    0px 1.127px 3.381px 0px rgba(0, 0, 0, 0.04),
    0px 2.01px 6.031px 0px rgba(0, 0, 0, 0.04),
    0px 3.76px 11.28px 0px rgba(0, 0, 0, 0.05),
    0px 9px 27px 0px rgba(0, 0, 0, 0.07);
}

.shadow-row {
  box-shadow: 0px 8px 8px rgba(101, 109, 120, 0.15);
}
.ql-editor.ql-blank::before {
  @apply !text-[#637381] !font-Inter !not-italic text-sm;
}
