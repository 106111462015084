.container {
  width: 100%;
  position: relative;
  z-index: 2;
  border-radius: 10px;
  background-color: #fefefe;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  overflow: hidden;
}

.container audio::-webkit-media-controls-panel {
  background-color: #fff;
}

.container audio::-webkit-media-controls-play-button {
  background-color: #b1d4e0;
  border-radius: 50%;
  margin-right: 5px;
}

.container audio::-webkit-media-controls-current-time-display,
.container audio::-webkit-media-controls-time-remaining-display {
  color: #000;
}

/* SIDEBAR */
.unseenTick {
  opacity: 1;
  width: 10px;
  height: 10px;
  background-color: #ff6c00;
  border-radius: 50%;
  position: absolute;
  left: 15px;
}

.sidebarContainer {
  background-color: transparent;
  min-width: 100%;
  height: 100%;
  color: #000;
  position: relative;
  transition: width 0.3s ease-in-out;
}

@media screen and (max-width: 1023px) {
  .sidebarContainer {
    height: calc(100% - 96px);
  }
  .sidebarContainer.showConversation {
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .sidebarContainer {
    height: calc(100% - 68px);
    padding-bottom: env(safe-area-inset-bottom);
  }
  @supports (-webkit-touch-callout: none) {
    .sidebarContainer {
      padding-bottom: calc(env(safe-area-inset-bottom) + 88px);
    }
  }
  .sidebarContainer.showConversation {
    height: 100%;
  }
}

.sidebarItem {
  display: flex;
  height: 88px;
  border-bottom: 1px solid #ebebeb;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  padding: 0 25px;
  padding-left: 40px;
}

.sidebarItem:hover,
.sidebarItem.active {
  background-color: #f3f3f3;
}

.sidebarUsername {
  font-size: 16px;
  display: block;
  color: #484848;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 122px;
}

.sidebarUnseenCount {
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: #ff6c00;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 13px;
  margin-top: 20px;
  color: #fff;
}

/* CHAT CONTENT */
.chatContent {
  height: calc(100% - 200px);
}
.chatContent > div {
  height: 100%;
}

.header {
  width: 100%;
  height: 88px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  color: #000;
  font-size: 22px;
}
.header & > div {
  cursor: pointer;
}
@media (max-width: 768px) {
  .header {
    justify-content: flex-start;
    gap: 8px;
    padding: 0 8px;
    height: 60px;
  }
}
.chatContainer {
  width: 100%;
  height: 100%;
  border-left: 1px solid #ebebeb;
  overflow: hidden;
}

.fileUploadContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
}
.fileUploadContainer #container-circles {
  position: fixed;
  bottom: 100px;
  cursor: pointer;
}

.statusTxt {
  color: #000;
  opacity: 0.8;
  font-size: 12px;
}

.inputWrapper {
  position: relative;
  width: 100%;
  height: 200px;
}

.inputContainer {
  margin: 0;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 0 10px;
  margin-top: 9px;
  margin-left: 40px;
  margin-bottom: 9px;
}

.input {
  margin: 0 10px;
  border-radius: 8px;
  width: 90%;
  color: #000;
  border: none;
  background: transparent;
  outline: none;
}

.iconContainer {
  cursor: pointer;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionsContainer {
  height: 104px;
  width: 100%;
  padding: 0px 30px;
}

.row {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 30px;
}

.rowItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
}

.chatBtn {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: rgb(var(--color-tripicalBgPrimary));
}

.chatBtn svg {
  width: 24px;
  height: 24px;
  fill: rgb(var(--color-tripicalContent));
}

.mapContainer {
  background-color: #9d9d9d;
  width: 307px;
  height: 250px;
  margin-bottom: 10px;
  border-radius: 25px;
  overflow: hidden;
  margin-top: 30px;
}
